<template>
  <div
    class="project container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <!-- <van-loading v-if="loading" /> -->
    <van-row>
      <van-col span="12">
        <span style="color: white; font-size: 12px">{{
          $t("public.text1")
        }}</span>
        <h2 class="white">{{ userInfo.info.balance }}</h2>
      </van-col>
      <van-col span="12" class="fz12 txt-right">
        <p style="color: white; margin: 10px 0 0 0; font-size: 14px">
          {{ $t("public.text41") }}:<strong style="color: white">{{
            config.yesterday_profit
          }}</strong>
        </p>
        <p style="color: white; margin: 0; font-size: 14px">
          {{ $t("public.text40") }}:<strong style="color: white">{{
            config.today_profit
          }}</strong>
        </p>
      </van-col>
    </van-row>
    <div class="chart-container">
      <div class="flex txt-center">
        <label
          v-for="item in dataList"
          :key="item.id"
          :class="activeIdent === item.ident ? 'active default2 noborder' : ''"
          @click="activeIdent = item.ident"
          >{{ item.name }}</label
        >
      </div>
      <van-tabs ref="tabs" v-model:active="activeTab" class="noshadow">
        <van-tab
          :title="item.name"
          v-for="item in activeChildkey"
          :key="item.name"
        />
      </van-tabs>
      <div>
        <div v-show="activeTab === 0" class="con fz12">
          <div ref="chart1" :style="{ width: '100%', height: '400px' }" />
        </div>
        <div v-show="activeTab === 1" class="fz12 txt-center">
          <van-row class="t-header">
            <van-col
              v-for="item in columnsHeader"
              :key="item.key"
              :span="item.key === 'code' ? '9' : '5'"
            >
              {{ item.name }}
            </van-col>
          </van-row>
          <div class="rollbox">
            <van-row
              v-for="(ditem, index) in allData.history_code"
              :key="index"
              class="t-body"
            >
              <van-col
                v-for="item in columnsHeader"
                :key="item.key"
                :span="item.key === 'code' ? '9' : '5'"
              >
                <div v-if="item.key === 'code'">
                  <div class="lottery-box">
                    <!-- 平双，平单，做空，做多，多双，空双，多单，空单 -->
                    <span class="kong" v-if="ditem.sum < 14"
                      >{{ $t("public.less") }}{{ item.sum }}</span
                    >
                    <span class="duo" v-if="ditem.sum >= 14">{{
                      $t("public.more")
                    }}</span>
                    <span class="ping" v-if="ditem.sum % 2 == 0">{{
                      $t("public.double")
                    }}</span>
                    <span class="ping" v-if="ditem.sum % 2 != 0">{{
                      $t("public.single")
                    }}</span>
                    <span
                      class="duo"
                      v-if="ditem.sum >= 14 && ditem.sum % 2 == 0"
                      >{{ $t("public.more double") }}</span
                    >
                    <span
                      class="kong"
                      v-if="ditem.sum <=14 && ditem.sum % 2 == 0"
                      >{{ $t("public.less double") }}</span
                    >
                    <span
                      class="duo"
                      v-if="ditem.sum >= 14 && ditem.sum % 2 != 0"
                      >{{ $t("public.more single") }}</span
                    >
                    <span
                      class="kong"
                      v-if="ditem.sum < 14 && ditem.sum % 2 != 0"
                      >{{ $t("public.less single") }}</span
                    >
                  </div>
                </div>
                <span v-else>{{ ditem[item.key] }}</span>
              </van-col>
            </van-row>
          </div>
        </div>
        <div v-show="activeTab === 2" class="con fz12">
          <div ref="chart3" :style="{ width: '92vw', height: '400px' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from "vue";
import { Toast } from "vant";
import * as echarts from "echarts";
import store from "@/store";
import { getLotteryHtry, getRooms } from "@/api/lottery";
import echartsJson from "../../utils/purple-passion.json";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { userInfo, config } = store.getters;
echarts.registerTheme("purple-passion", echartsJson);
// const  ident=['DOT','BTC','ETH']
const columnsHeader = [
  { name: t("public.text24"), key: "issue" },
  { name: t("public.text32"), key: "sum" },
  { name: t("public.text25"), key: "code" },
  { name: t("public.text26"), key: "belong_date" },
];

// const loading = ref(true);
const allData = ref({}); // 存储所有的值
const dataList = ref([]); // 获取彩种列表
const activeIdent = ref(""); //渲染第一选项卡
const activeTab = ref(0); // 子选项卡索引
const activeChildkey = [
  { name: t("public.text4"), key: "column2" },
  { name: t("public.text5"), key: "history_code" },
  { name: t("public.text6"), key: "Candle" },
];
const getData = () => {
  const toastLoading = Toast.loading({
    message: t("public.loading"),
    duration: 0,
  });
  getRooms()
    .then((res) => {
      if (res.data && res.data[0]) {
        activeTab.value = 0;
        dataList.value = res.data;
        if (!activeIdent.value) {
          activeIdent.value = res.data[0].ident;
        }
        getChartData();
      }
    })
    .catch(() => {
      toastLoading.clear();
    });
};
getData();
const getChartData = () => {
  getLotteryHtry({ ident: activeIdent.value })
    .then((res) => {
      allData.value = res.data;
      setChartData();
    })
    .finally(() => {
      Toast.clear();
    });
};
const chart1 = ref();
const chart3 = ref();
const setChartData = () => {
  nextTick(() => {
    console.log(this);
    const myChart1 = echarts.init(chart1.value, "purple-passion");
    const myChart3 = echarts.init(chart3.value, "purple-passion");
    const mySeries = [];
    const myCategories = allData.value.column2.categories;
    const myCategories2 = allData.value.Candle.categories;
    const seriesData = allData.value.Candle.series.data;
    // 组装数据
    allData.value.column2.series.forEach((item) => {
      mySeries.push({
        name: item.name,
        type: "bar",
        stack: "total",
        label: {
          show: true,
          textStyle: {
            color: "#fff", // 设置文字颜色
          },
        },
        emphasis: {
          focus: "series",
        },
        data: item.data,
      });
    });

    // 绘制图表
    myChart1.setOption({
      legend: {
        top: "2%",
      },
      grid: {
        left: "0%",
        right: "0%",
        bottom: "15%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
      },
      xAxis: {
        type: "category",
        data: myCategories,
      },
      series: mySeries,
      dataZoom: {
        show: true, // 为true 滚动条出现
        backgroundColor: "#302e55",
        realtime: true,
        type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
        height: 20, // 表示滚动条的高度，也就是粗细
        start: 0, // 表示默认展示20%～80%这一段。
        end: 10,
      },
    });
    // K线图
    myChart3.setOption({
      legend: {
        show: false,
      },
      grid: {
        top: "1%",
        left: "0%",
        right: "2%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: {
        data: myCategories2,
        scale: true,
        boundaryGap: false,
        axisLine: {
          onZero: false,
        },
        splitLine: {
          show: false,
        },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
      yAxis: {
        scale: true,
        splitArea: {
          show: true,
        },
      },
      series: [
        {
          name: allData.value.Candle.series.name,
          type: "candlestick",
          data: seriesData,
        },
      ],
      dataZoom: {
        show: true, // 为true 滚动条出现
        backgroundColor: "#302e55",
        realtime: true,
        type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
        height: 20, // 表示滚动条的高度，也就是粗细
        start: 0, // 表示默认展示20%～80%这一段。
        end: 50,
      },
    });
  });
};
watch(activeIdent, (value) => {
  console.log("===watch====", value, activeTab.value);
  getData();
});
</script>

<style lang="less" scoped>
.project {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .fz12 strong {
    font-size: 16px;
  }

  .white {
    margin: 0;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }

  .t-header {
    background: #211f41;
    border: 1px solid #302e55;
    border-bottom: none;
    line-height: 30px;
  }

  .t-body {
    padding: 5px 0;
    line-height: 20px;

    .t-b-block {
      display: inline-block;
      height: 20px;
      padding: 2px 5px;
    }

    &:nth-of-type(2n + 1) {
      background: #2d2b4d;
    }
  }
  ::v-deep .van-row {
    padding: 25px 15px;
  }
  .container {
    padding: 0px !important;
  }
  :deep(.lottery-box) {
    color: #fff;
    text-align: center;

    span {
      font-size: 12px;
      background: #8b7df6;

      &.duo {
        background: #25ab0e;
      }

      &.kong {
        background: #e33a20;
      }

      &::after {
        display: none;
      }
    }
  }

  .rollbox {
    height: 69vh;
    padding-bottom: 10px;
    border: 1px solid #302e55;
    box-shadow: 0 1px 5px 1px #00000052;
  }

  .flex {
    position: relative;
    font-size: 25px;
    label {
      width: 33%;
      padding: 10px;
      display: inline-block;

      &.active {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 0px 0px 5px 0 #00000052;
      }
    }
  }

  .noshadow {
    .van-tabs__wrap {
      box-shadow: 1px 1px 5px 1px #00000052;
    }
  }
  ::v-deep .lottery-box span,
  .lottery-box .sum {
    width: 20vw !important;
    height: 6vw !important;
    line-height: 6vw !important;
    color: #fff !important;
  }
  ::v-deep .van-row {
    padding: 0vw 4vw !important;
  }
  ::v-deep .van-col--5 {
    margin: auto !important;
  }
  ::v-deep .t-header {
    background: rgb(51, 94, 253) !important;
    border-bottom: none !important;
    color: #fff !important;
    font-size: 16px !important;
    line-height: 12vw !important;
  }
}
</style>
