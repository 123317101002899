<template>
  <div class="home container">
    <van-swipe :autoplay="3000" lazy-render v-if="configInfo.carousel">
      <van-swipe-item v-for="image in configInfo.carousel" :key="image.id">
        <img :src="image.src" @click="openLink(image.link)" />
      </van-swipe-item>
    </van-swipe>
    <!-- 公告 -->
    <div class="notice con border-r5 mt-10 shadow">
      <van-icon name="volume-o" size="1.4rem"></van-icon>

      <b>{{ $t("public.text40") }} :{{ configInfo.today_profit }}</b>
    </div>
    <van-row justify="space-between" class="box mt-10">
      <van-col
        class="default shadow border-r5"
        v-for="item in configInfo.lottery"
        :key="item.code"
      >
        <span class="tit">{{ item.name }}</span>
        <p class="issue">{{ item.issue || "- - - - -" }}</p>
        <div class="code">
          <span
            v-for="(number, idx) in String(item.code).split('')"
            :key="idx"
            >{{ number }}</span
          >
        </div>
      </van-col>
    </van-row>
    <!-- 中间 -->
    <van-row justify="space-between" class="trade mt-10">
      <van-col
        @click="goPagePath('/pages/lottery')"
        class="left default shadow border-r5"
      >
        <h1>Trade</h1>
        <span class="fz16">{{ $t("menus.text7") }}</span>
        <div
          class="def_img"
          :style="{ backgroundImage: 'url(img/home_type.png)' }"
        ></div>
      </van-col>
      <van-col class="right">
        <div
          class="default shadow border-r5"
          @click="showPicker = true"
          :style="{ backgroundImage: 'url(img/type_home.png)' }"
        >
          <span>{{ $t("menus.text5") }}</span>
        </div>
        <div
          class="default shadow border-r5"
          @click="goPagePath('/pages/activity')"
          :style="{ backgroundImage: 'url(img/act_home.png)' }"
        >
          <span>{{ $t("menus.text8") }}</span>
        </div>
      </van-col>
    </van-row>
    <!-- 列表 -->
    <van-tabs v-model:active="active" class="mt-10 border-r5">
      <van-tab :title="$t('menus.text9')">
        <van-row
          v-for="(item, index) in quoteList"
          :key="index"
          class="list default shadow border-r5 mt-5"
          justify="space-between"
        >
          <van-col span="10" style="margin: auto">
            <div
              :style="{ backgroundImage: 'url(' + item.image + ')' }"
              class="right"
            >
              <label>{{ item.base }}/{{ item.quote }}</label>
              <span>{{ item.vol }}</span>
            </div>
          </van-col>
          <van-col
            span="7"
            :class="
              'pri ' + (item.close > 0 ? 'green' : item.close < 0 ? 'red' : '')
            "
            >{{ item.close }}</van-col
          >
          <van-col span="7" class="rise">
            <van-tag
              round
              :type="
                item.rate > 0 ? 'success' : item.rate < 0 ? 'warning' : 'danger'
              "
              >{{ item.rate }} %
            </van-tag>
          </van-col>
        </van-row>
      </van-tab>
      <van-tab :title="$t('menus.text10')">
        <van-row class="header mt-10">
          <van-col span="6">{{ $t("menus.text11") }}</van-col>
          <van-col span="6">{{ $t("menus.text12") }}</van-col>
          <van-col span="6">{{ $t("menus.text13") }}</van-col>
          <van-col span="6">{{ $t("menus.text14") }}</van-col>
        </van-row>
        <div class="margue border-r5">
          <div class="inner">
            <van-row
              v-for="(item, index) in 25"
              :key="index"
              class="h-list mt-10"
            >
              <van-col span="6" class="blue"
                >****{{ random(1111, 9999) }}</van-col
              >
              <van-col span="6">{{ randomName[random(0, 2)] }}</van-col>
              <van-col span="6" class="blue"
                >{{ random(100, 10000) }}.{{ random(0, 99) }}</van-col
              >
              <van-col span="6">{{ today }}</van-col>
            </van-row>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onLangConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from "vue";
import { Toast } from "vant";
import { getQuotes, getConfigs } from "@/api/public";
import store from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

onBeforeUnmount(() => {
  clearInterval(timer);
  clearInterval(timerList);
  getData = () => {};
  getDatalist = () => {};
});
const { locale } = useI18n();
const router = useRouter();
const active = ref(0);
const { userInfo } = store.getters;
const quoteList = ref([]);
const configInfo = ref({});
const d = new Date();
const today = d.getMonth() + 1 + "/" + d.getDate();
Toast.loading({
  duration: 0,
  forbidClick: false,
});
const randomName = ["BTC", "ETH", "DOT"];
const random = (lower, upper) => {
  return Math.floor(Math.random() * (upper - lower)) + lower;
};
const todayProfit = random(3000000, 4000000);
getQuotes(1)
  .then((res) => {
    quoteList.value = res.data;
  })
  .finally(() => {
    Toast.clear();
  });
getConfigs()
  .then((res) => {
    store.commit("common/SET_CONFIG", res);
    configInfo.value = res;
  })
  .finally(() => {
    Toast.clear();
  });

let timer;
let timerList;
let getData = () => {
  timer = setTimeout(() => {
    getQuotes(1)
      .then((res) => {
        quoteList.value = res.data;
      })
      .finally(() => {
        getData();
      });
  }, 3000);
};
getData();

let getDatalist = () => {
  timer = setTimeout(() => {
    getConfigs()
      .then((res) => {
        store.commit("common/SET_CONFIG", res);
        configInfo.value = res;
      })
      .finally(() => {
        getDatalist();
      });
  }, 50000);
};
getDatalist();

const openLink = (link) => {
  if (!link) {
    return;
  }
  if (link.indexOf("http") !== -1) {
    window.open(link);
  } else {
    goPagePath(link);
  }
};
const goPagePath = (path) => {
  router.push(path);
};
const showPicker = ref(false);
const columns = ["简体中文", "English"];
const onLangConfirm = (value) => {
  const str = value === "English" ? "en" : "cn";
  localStorage.setItem("lang", str);
  locale.value = str;
  showPicker.value = false;
};
</script>

<style lang="less" scoped>
.home {
  .van-swipe {
    width: 100%;
    height: 125px;
    text-align: center;

    .van-swipe-item {
      img {
        max-width: 99%;
        max-height: 100%;
        border-radius: 5px;
      }
    }
  }

  .notice {
    background-color: #302e55;
    color: #fff;
    border-radius: 25px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    i {
      position: absolute;
      left: 20px;
    }
  }

  .box {
    height: 90px;
    text-align: center;
    margin: 20px 0px;

    .van-col {
      font-size: 18px;
      width: 100%;
      background-color: #302e55;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      &:first-child .tit {
        color: #19be6b;
      }

      &:nth-child(2) .tit {
        color: #19be6b;
      }

      &:last-child .tit {
        color: #19be6b;
      }
    }

    .default {
      width: 32%;
      padding: 8px 0px 4px 0px;

      .issue {
        font-size: 20px;
        color: #fff;
        margin: 8px 0;
      }

      .code {
        margin-top: 2px;
        span {
          font-weight: bold;
          font-size: 15px;
          position: relative;
          line-height: 23px;
          color: #fff;
          margin-top: 6px;
        }
      }
    }

    p {
      margin: 5px 0;
      padding: 0;
      font-size: 22px;
      font-weight: normal;
    }
  }

  .trade {
    height: 110px;

    .left {
      padding: 10px 13px;
      width: 49%;
      position: relative;
      background-color: #302e55;
      position: relative;
      overflow: hidden;
      .def_img {
        position: absolute;
        width: 150px;
        height: 150px;
        right: -30px;
        bottom: -40px;
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .left {
      background-size: 170px 170px;
      background-repeat: no-repeat;
      background-position: 50px 30px;

      h1 {
        font-size: 25px;
        margin: 0 0 10px 0;
      }
      span {
        font-size: 12px;
      }
    }

    .right {
      width: 49%;
      div {
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position: 20px;
        height: 46%;
        border-radius: 10px;
        background-color: #302e55;
        font-size: 15px;
        color: #f0f0f0;
        font-weight: 700;

        &:last-child {
          margin-top: 5px;
        }

        span {
          margin-top: 21px;
          margin-left: 70px;
          display: inline-block;
        }
      }
    }
  }
  ::v-deep .van-tabs__line {
    position: absolute;
    bottom: 4vw;
    left: 0;
    z-index: 1;
    width: var(--van-tabs-bottom-bar-width);
    height: var(--van-tabs-bottom-bar-height);
    background: var(--van-tabs-bottom-bar-color);
    border-radius: var(--van-tabs-bottom-bar-height);
    width: 50%;
  }
  ::v-deep .van-tab__text--ellipsis {
    font-size: 15px;
    color: rgb(187, 187, 187);
  }
  .van-tab__panel {
    font-size: 13px;
    text-align: center;
    color: #ddd;

    .header {
      padding-bottom: 10px;
      color: #999;
    }

    .margue {
      height: 250px;
      overflow: hidden;
      background: #302e55;
      box-shadow: inset 1px 1px 2px 1px #090920;
      border: 1px solid #201f33;
    }
  }
}

.inner {
  animation: myMove 15s linear infinite;
  animation-fill-mode: forwards;
}

/*文字停顿滚动*/
@keyframes myMove {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-240px);
  }
}
</style>
