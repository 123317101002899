import { createApp } from "vue";
import mitt from "mitt";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// normalize.css
import "normalize.css/normalize.css";
// 全局样式
import "@/styles/index.less";
// 按需注册 vant 组件
import { registerVantComp } from "@/plugins/registerVant";

// svg-icon
import { registerSvgIconComp } from "@/plugins/registerSvgIcon";
import vueI18n from './lang';

const bus = mitt();
const app = createApp(App);
app.config.globalProperties.$bus = bus;
registerVantComp(app);
registerSvgIconComp(app);

app.use(vueI18n).use(router).use(store).mount("#app");
