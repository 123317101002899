<template>
  <van-tabbar v-model="active" fixed :placeholder="true" :route="true">
    <van-tabbar-item
      v-for="(item, index) in tabbarData"
      :key="index"
      :icon="item.icon"
      :to="item.to"
    >
      <template v-if="item.svgname" #icon="{}">
        <svg-icon :icon-class="item.svgname" />
      </template>
      {{$t(item.title)}}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  name: "Tabbar",
  setup() {
    const active = ref(0);
    const tabbarData = reactive([
      {
        icon: "wap-home-o",
        title: "menus.home",
        to: {
          name: "home",
        },
      },
      {
        icon: "chart-trending-o",
        title: "menus.trend",
        to: {
          name: "project",
        },
      },
      {
        svgname: "btc",
        title: "menus.quotation",
        to: {
          name: "quote",
        },
      },
      {
        icon: "service-o",
        title: "menus.customer",
        to: {
          name: "customer",
        },
      },
      {
        icon: "user-o",
        title: "menus.my",
        to: {
          name: "my",
        },
      },
    ]);
    return {
      active,
      tabbarData,
    };
  },
};
</script>

<style></style>
