import request from '@/utils/request';

export function uploadImg(file) {
  const data = new FormData();
  data.append('file', file);
  return request({
    url: '/user/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}
// 行情接口
export function getQuotes( sort = 0 ) {
  return request({
    url: '/quotes',
    method: 'get',
    params:{ sort }
  });
}
// 首页接口
export function getConfigs() {
  return request({
    url: '/index/config',
    method: 'get'
  });
}
// 活动列表
export function getActivity() {
  return request({
    url: '/activity',
    method: 'get'
  });
}
export function getActivityDetail(ident) {
  return request({
    url: `/activity/activityinfo?name=${ident}`,
    method: 'get'
  });
}
