<template>
  <div class="app-wrapper">
    <Header />
    <div class="app-wrapper__content">
      <router-view v-slot="{ Component }">
         <component :is="Component" />
      </router-view>
    </div>
    <div class="app-wrapper__footer" v-if="showFooter">
      <tabbar />
    </div>
  </div>
</template>

<script>
import { getConfigs } from "@/api/public";
import Header from "@/components/Header";
import setPageTitle from "@/utils/set-page-title.js";
import Tabbar from "@/components/Tabbar";
import { onMounted, computed, ref, watch, getCurrentInstance } from "vue";
import { getUserInfo, getBalance } from "@/api/user";
import store from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: "Layout",
  components: {
    Tabbar,
    Header,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const showFooter = ref(false);
    getConfigs().then((res) => {
      store.commit("user/SET_USERINFO_PUSH", res);
    });
    watch(
      () => router.currentRoute.value.name,
      (newValue) => {
        console.log("watch", newValue);
        const { header, title } = router.currentRoute.value.meta;
        showFooter.value = !header;
        // 设置页面 title
        if (typeof title !== "string" || title.indexOf("function") !== -1) {
          try {
            // eslint-disable-next-line no-eval
            const fn = eval(title);
            title = fn(to.params.type || to.query.type, to.query);
            setPageTitle(t(title));
          } catch (e) {
            console.log(e);
          }
        } else {
          setPageTitle(t(title));
        }
      },
      { immediate: true, deep: true }
    );
    const keepAliveRoutes = computed(() => store.getters.cachedViews);

    const getBalanceBus = () => {
      getBalance().then((res) => {
        store.commit("user/SET_USERINFO_PUSH", res.data);
      });
      getUserInfo().then((res) => {
        store.commit("user/SET_USERINFO_PUSH", res.data);
      });
    };
    getBalanceBus();
    onMounted(() => {
      const ins = getCurrentInstance();
      const bus = ins.appContext.config.globalProperties.$bus;
      bus.on("getMoney", () => {
        getBalanceBus();
        console.dir("=====getMoney====", store.getters);
      });
    });
    return {
      showFooter,
      keepAliveRoutes,
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/mixin.less";

.app-wrapper {
  .clearfix();
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
