import Layout from "@/layout";
import Home from "@/views/home";
import Quote from "@/views/quote";
import Project from "@/views/project";
import Customer from "@/views/customer";
import My from "@/views/my";
import Login from "@/views/login";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "menus.login",
    },
  },
  {
    path: "/",
    name: "root",
    component: Layout,
    redirect: "Home",
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
        meta: {
          title: "menus.home",
          noCache: true,
        },
      },
      {
        path: "quote",
        name: "quote",
        component: Quote,
        meta: {
          title: "menus.quotation",
          noCache: true,
        },
      },
      {
        path: "project",
        name: "project",
        component: Project,
        meta: {
          title: "menus.trend",
          noCache: true,
        },
      },
      {
        path: "customer",
        name: "customer",
        component: Customer,
        meta: {
          title: "menus.customer",
        },
      },
      {
        path: "my",
        name: "my",
        component: My,
        meta: {
          title: "menus.my",
          noCache: true,
        },
      },
    ],
  },
];
const routesHeader = [
  {
    path: "/pages",
    name: "pages",
    component: Layout,
    redirect: "message",
    children: [
      {
        path: "activity",
        name: "activity",
        component: () => import("@/views/activity"),
        meta: {
          header: true,
          title: "menus.activity",
        },
      },
      {
        path: "activityDetail",
        name: "activityDetail",
        component: () => import("@/views/activity/detail"),
        meta: {
          header: true,
          title: "menus.activityDetail",
        },
      },
      {
        path: "message",
        name: "message",
        component: () => import("@/views/my/msg"),
        meta: {
          header: true,
          noCache: true,
          title: "menus.message",
        },
      },
      {
        path: "recharge",
        name: "recharge",
        component: () => import("@/views/recharge"),
        meta: {
          header: true,
          title: "充值渠道",
        },
      },
      {
        path: "rechargeType",
        name: "rechargeType",
        component: () => import("@/views/recharge/rechargeType"),
        meta: {
          header: true,
          title: (type) => `${type}充值`,
        },
      },
      {
        path: "withdrawal",
        name: "withdrawal",
        component: () => import("@/views/withdrawal"),
        meta: {
          header: true,
          title: "menus.withdrawal",
        },
      },
      {
        path: "password",
        name: "password",
        component: () => import("@/views/password"),
        meta: {
          header: true,
          title: "menus.password3",
        },
      },
      {
        path: "cards",
        name: "cards",
        component: () => import("@/views/cards"),
        meta: {
          header: true,
          title: "menus.bank",
        },
      },
      {
        path: "card/add",
        name: "cardAdd",
        component: () => import("@/views/cards/add"),
        meta: {
          header: true,
          title: (type) => {
            if (type === "add") {
              return "menus.bankadd";
            } else if (type === "detail") {
              return "menus.viewbank";
            }
            return "menus.bankchange";
          },
        },
      },
      {
        path: "lottery",
        name: "lottery",
        component: () => import("@/views/lottery"),
        meta: {
          header: true,
          title: "menus.investment",
        },
      },
      {
        path: "lottery/info",
        name: "lotteryInfo",
        component: () => import("@/views/lottery/info"),
        meta: {
          header: true,
          title: "menus.pay",
        },
      },
      {
        path: "play",
        name: "play",
        component: () => import("@/views/lottery/play"),
        meta: {
          noCache: true,
          header: true,
          title: (type, obj) => `${obj.name}(${type})`,
        },
      },
      {
        path: "usdt",
        name: "usdt",
        component: () => import("@/views/usdt"),
        meta: {
          header: true,
          title: "menus.wallet",
        },
      },
      {
        path: "usdt/add",
        name: "usdtAdd",
        component: () => import("@/views/usdt/add"),
        meta: {
          header: true,
          title: (type) => {
            if (type === "add") {
              return "menus.walletadd";
            } 
            return "menus.walletchange";
          },
        },
      },
      {
        path: "passwordChange",
        name: "passwordChange",
        component: () => import("@/views/password/change"),
        meta: {
          header: true,
          title: (type) => {
            if (type === "safe") {
              return "menus.password4";
            }
            return "menus.password5";
          },
        },
      },
      {
        path: "investment/:type",
        name: "investment",
        component: () => import("@/views/investment"),
        meta: {
          noCache: true,
          header: true,
          title: (type) => {
            if (type === "lottery") {
              return "menus.text1";
            }
            if (type === "pay") {
              return "menus.text2";
            }
            if (type === "lotteryHistory") {
              return "menus.text3";
            }
            if (type === "take") {
              return "menus.text4";
            }
          },
        },
      },
    ],
  },
];

export default routes.concat(routesHeader);
