import request from '@/utils/request';

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  });
}
export function getBalance() {
  return request({
    url: '/user/balance',
    method: 'get'
  });
}
export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data
  });
}
export function addCard(data) {
  return request({
    url: '/user/addBank',
    method: 'put',
    data
  });
}
export function getBanks(params) {
  return request({
    url: "/user/banks",
    method: "get",
    params,
  });
}
export function getBanksName(params) {
  return request({
    url: '/user/addBank',
    method: 'get',
    needMsg:true,
    params
  });
}
export function getCoinWallet() {
  return request({
    url: '/user/coinWallet',
    method: 'get'
  });
}
export function setCoinWallet(data) {
  return request({
    url: '/user/coinWallet',
    method: 'post',
    data
  });
}
export function setLoginPwd(data) {
  return request({
    url: '/user/Password',
    method: 'put',
    data
  });
}
export function setSecurityPwd(data) {
  return request({
    url: '/user/SecurityPassword',
    method: 'put',
    data
  });
}

export function getUserInfo() {
  return request({
    url: '/user/info',
    method: 'get'
  });
}
// 系统消息
export function getNotice() {
  return request({
    url: '/notice',
    method: 'get'
  });
}
// 个人消息
export function getMessage() {
  return request({
    url: '/message/inbox',
    method: 'get'
  });
}
// 个人消息 已读
export function readMessage(data) {
  return request({
    url: '/message/read',
    method: 'post',
    data
  });
}
export function getCaptcha() {
  return `${process.env.VUE_APP_BASE_API  }/captcha?t=${  new Date().valueOf()}`;
}
