<template>
  <router-view />
  <!-- <h1 v-else class="txt-center con">请用手机访问</h1> -->
</template>

<script setup>
import { Locale } from "vant";
import en from "vant/es/locale/lang/en-US";
import zh from "vant/es/locale/lang/zh-CN";
import { browser } from "@/utils";
// const isMobile = browser.versions.mobile;
if (localStorage.getItem("lang") === "en") {
  Locale.use("en-US", en);
} else {
  Locale.use("zh-CN", zh);
}
</script>

<style></style>
