<template>
  <div
    class="quote container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <h3 class="white">{{ $t("public.text7") }}</h3>
    <div class="rollbox">
      <van-loading v-if="loading" />
      <!-- 列表 -->
      <van-row
        v-else
        v-for="(item, index) in dataList"
        :key="index"
        class="list default shadow border-r5 mt-10"
        justify="space-between"
      >
        <van-col span="11" style="margin: auto">
          <div
            :style="{ backgroundImage: 'url(' + item.image + ')' }"
            class="right"
          >
            <label>{{ item.base }}/{{ item.quote }}</label>
            <span>{{ item.vol }}</span>
          </div>
        </van-col>
        <van-col
          span="6"
          :class="
            'txt-center pri ' +
            (item.close > 0 ? 'green' : item.close < 0 ? 'red' : '')
          "
          >{{ item.close }}</van-col
        >
        <van-col span="7" class="rise">
          <van-tag
            round
            :type="
              item.rate > 0 ? 'success' : item.rate < 0 ? 'warning' : 'danger'
            "
            >{{ item.rate }}%</van-tag
          >
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from "vue";
// import { dataPrice } from '../home/data.js'
import { getQuotes } from "@/api/public";
const loading = ref(true);
const dataList = ref([]);
getQuotes().then((res) => {
  dataList.value = res.data;
  loading.value = false;
});

let timer;
let getData = () => {
  timer = setTimeout(() => {
    getQuotes()
      .then((res) => {
        dataList.value = res.data;
      })
      .finally(() => {
        getData();
      });
  }, 1000);
};
getData();
onBeforeUnmount(() => {
  console.log("~~~", timer);
  clearTimeout(timer);
  getData = () => {};
});
</script>

<style lang="less" scoped>
.quote {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  .white {
    margin: 0;
    padding: 25px 15px;
    font-size: 24px;
  }
  .rollbox {
    height: 79vh;
    padding-bottom: 20px;
  }
}
</style>
