import axios from "axios";
import {
  Toast,
  Dialog,
  Notify
} from "vant";
import store from "@/store";
// 创建实例
const service = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // timeout: 5000 // 请求超时
});
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 发送请求前，可在此携带 token
    const token = store.getters.userInfo ? store.getters.userInfo.token : null;
    if (token) {
      // document.cookie = 'id=' + token
      // config.headers['token'] = token
    }
    config.headers["accept-language"] = localStorage.getItem("lang") || "cn";
    return config;
  },
  (error) => {
    // 请求错误，可在此进行统一错误处理
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status && res.status != 0) {
      // Toast.fail({
      //   message: res.msg || 'Error',
      //   duration: 5 * 1000
      // })
      console.log(response, service);
      if (!response.config.needMsg) {
        Toast.clear();
        Notify({
          type: "warning",
          message: res.msg,
        });
        return Promise.reject(new Error(res.msg || "Error"));
      }
    }
    return res;
  },
  (error) => {
    Toast.clear();
    if (error.response.status === 401) {
      Dialog.alert({
        message: "登录超时，请重新登录!",
      }).then(() => {
        store.commit("user/DEL_USERINFO");
        location.reload();
      });
    } else if (error.response.status === 422) {
      setTimeout(() => {
        Toast.fail({
          message: error.response.data.errors.total[0],
          duration: 2 * 1000,
        });
      }, 500);

    } else {
      setTimeout(() => {
        Toast.fail({
          message: error.message,
          duration: 2 * 1000,
        });
      }, 500);
    }
    return Promise.reject(error);
  }
);

export default service;