import request from '@/utils/request';

// 获取房间信息
export function getRooms() {
  return request({
    url: '/lottery/gameRooms',
    method: 'get'
  });
}
// 获取CAI种详情信息
export function getCaiType(params) {
  return request({
    url: '/lottery/index',
    method: 'get',
    params
  });
}
// 获取当前奖期和倒计时
export function getCuntTime(params) {
  return request({
    url: '/lottery/issue',
    method: 'get',
    params
  });
}
// 获取历史开奖信息
export function getLotteryHtry(params) {
  return request({
    url: '/lottery/bonuscode',
    method: 'get',
    params
  });
}
// 投注
export function lotteryBet(data,ident) {
  return request({
    url: `/lottery/bet?ident=${ident}`,
    method: 'post',
    data
  });
}