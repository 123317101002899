const state = {
  config: []
};

const mutations = {
  SET_CONFIG: (state, data) => {
    state.config = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations
};
