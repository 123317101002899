<template>
  <van-nav-bar
    v-if="show"
    :title="headTitle"
    :left-text="$t('public.back')"
    left-arrow
    fixed
    placeholder
    @click-left="goBack"
  >
    <template #right v-if="showRight">
      <van-popover
        v-model:show="showPopover"
        :actions="actions"
        @select="goPage"
      >
        <template #reference>
          <van-icon name="ellipsis" size="22" />
        </template>
      </van-popover>
    </template>
  </van-nav-bar>
</template>

<script setup>

import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
 const { t } = useI18n();
const router = useRouter();
const headTitle = ref();
const show = ref(false);
const showRight = ref(false);
const showPopover = ref(false);
const actions = [
  { text:t('menus.text1'), path: "/pages/investment/lottery" },
  // { text:t('menus.text3'), path: "/pages/investment/lotteryHistory" },
  { text:t('menus.pay'), path: "/pages/lottery/info" },
];
watch(
  () => router.currentRoute.value.name,
  (newValue) => {
    console.log("watch", newValue);
    if (newValue === "play") {
      showRight.value = true;
    } else {
      showRight.value = false;
    }
    const { title, header } = router.currentRoute.value.meta;
    let tit = title;
    show.value = header;
      try {
        // eslint-disable-next-line no-eval
        const fn = eval(tit);
        const { params, query } = router.currentRoute.value;
        const type = params.type || query.type;
        const obj = Object.assign({}, params, query);
        tit = fn(type, obj);
      } catch (e) {
        // console.log(e)
      }
    headTitle.value = t(tit);
  },
  { immediate: true }
);

console.log(router.currentRoute.value);
const goBack = () => {
  router.back();
  console.log(router);
};
const goPage = (item) => {
  console.log(item);
  router.push({ path: item.path, query: router.currentRoute.value.query });
};
</script>

<style></style>
