<template>
  <div class="my container">
    <van-row class="my-top">
      <van-col span="4" class="fz30">
        <van-icon name="service-o" @click="openWindow(kefu_url)" />
      </van-col>
      <van-col span="16" class="txt-center">
        <img src="@/assets/user.webp" class="my-img" />
        <!-- <p style="margin: 6px">
          {{ $t("login.Invitation") }}:{{ userInfo.info.reg_code }}
        </p> -->
        <p>
          ID:
          <span class="regid"> {{ userInfo.info.id }}</span>
        </p>
        <p v-if="userInfo.info.user_type_id != 3">
          {{ $t("login.Invitation") }}:
          <span class="regcode">
            {{ userInfo.info.reg_code }}
          </span>
        </p>

        <!-- <p>{{ $t("public.text12") }}:{{ userInfo.info.last_time }}</p> -->
      </van-col>
      <!-- <van-col span="4" class="txt-right fz30">
        <van-icon name="chat-o" @click="goPage('/pages/message')" />
      </van-col> -->
    </van-row>
    <!-- 资 金 -->
    <van-row class="my-money txt-center">
      <van-col span="8" class="rel">
        <label
          >{{ $t("public.text1") }}
          <img
            src="@/assets/reload.png"
            :class="'reload ' + (reloadShow ? 'load-animate' : '')"
            @click="reload"
        /></label>
        <h1>{{ userInfo.info.balance }}</h1>
      </van-col>
      <van-col span="8">
        <label>{{ $t("public.text3") }}</label>
        <h1>
          {{ userInfo.info.refund_today }}
        </h1>
      </van-col>
      <van-col span="8">
        <label>{{ $t("public.text13") }}</label>
        <h1>{{ userInfo.info.lock_balance }}</h1>
      </van-col>
    </van-row>
    <input
      id="regCode"
      readonly
      :value="userInfo.info.reg_code"
      style="display: none"
    />
    <!-- 底部 -->
    <!-- 列表 -->
    <div class="my-list default shadow border-r5">
      <!-- @click="openWindow(kefu_url)"-->
      <van-button
        icon="balance-o"
        square
        size="large"
        type="primary"
        @click="goPage('/pages/recharge')"
        >{{ $t("public.text22") }}
      </van-button>
      <van-button
        icon="cash-back-record"
        square
        size="large"
        type="success"
        @click="goPage('/pages/withdrawal')"
        >{{ $t("public.text23") }}
      </van-button>
      <van-cell
        v-for="(item, index) in menus"
        :key="index"
        :title="item.name"
        is-link
        @click="goPage(item.path)"
      >
        <template #icon>
          <van-icon :name="item.icon" size="30" />
        </template>
      </van-cell>

      <van-cell
        :title="$t('public.text21')"
        is-link
        @click="openWindow(guanwang_url)"
      >
        <template #icon>
          <van-icon name="guide-o" color="red" size="30" />
        </template>
      </van-cell>
      <van-cell :title="$t('public.text14')" is-link @click="loginOut">
        <template #icon>
          <van-icon :name="image_List" size="30" />
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { browserVersion1 } from "@/utils/indexList";
import store from "@/store";
const { userInfo } = store.getters;
const { t } = useI18n();
const reloadShow = ref(false);
const image_List = require("../../assets/n.png");
const menus = [
  {
    name: t("public.text15"),
    icon: require("../../assets/xz.png"),
    path: "/pages/investment/lottery",
  },
  {
    name: t("public.text16"),
    icon: require("../../assets/z.png"),
    path: "/pages/investment/pay",
  },
  {
    name: t("public.text17"),
    icon: require("../../assets/x.png"),
    path: "/pages/investment/take",
  },
  {
    name: t("public.text18"),
    icon: require("../../assets/c.png"),
    path: "/pages/password",
  },
  {
    name: t("public.text19"),
    icon: require("../../assets/v.png"),
    path: "/pages/cards",
  },
  {
    name: t("public.text20"),
    icon: require("../../assets/b.png"),
    path: "/pages/usdt",
  },
];
const router = useRouter();
const goPage = (path) => {
  if (!path) {
    return;
  }
  router.push({ path: path });
};
const { guanwang_url, kefu_url, id } = userInfo.info;
const openWindow = (path) => {
  var userInfo = { uid: id };
  // 这里转换成 字符串
  let navData = JSON.stringify(userInfo);
  if (browserVersion1().android) {
    window.android.pay(kefu_url + `&userInfo=${navData}`);
  } else {
    window.location.href = kefu_url + `&userInfo=${navData}`;
  }
};
const ins = getCurrentInstance();
const bus = ins.appContext.config.globalProperties.$bus;
const reload = () => {
  reloadShow.value = true;
  //更新余额
  bus.emit("getMoney");
  setTimeout(() => {
    reloadShow.value = false;
  }, 1000);
};
const copyNumber = () => {
  const copyBtn = document.getElementById("regcode");
  copyBtn.select(); // 选择对象
  document.execCommand("Copy");
  Toast.success(t("public.success"));
};

const loginOut = () => {
  store.commit("user/DEL_USERINFO");
  router.replace({ path: "/login" });
};

onBeforeMount(() => {
  reload();
});
</script>

<style lang="less" scoped>
::v-deep .van-row {
  padding: 10px 0px 0px 0px;
}
.my {
  .regcode {
    color: #0cc00c;
  }
  .regid {
    color: #e9482f;
  }
  .my-img {
    max-width: 60px;
    border-radius: 50%;
  }
  .reload {
    width: 16px;
    position: absolute;
    right: 6px;
    top: 0;
  }

  .load-animate {
    animation: rotate360 1s infinite;
  }

  @keyframes rotate360 {
    /* 起始点是0秒，此处可省略动画起点from */
    to {
      /* 避免translate被覆盖，此处可再添加translate */
      transform: rotate(360deg);
    }
  }
  .my-money {
    font-size: 14px;

    h1 {
      margin: 10px 0;
      font-size: 16px;
    }
  }
  .van-button--large {
    width: 45% !important;
    border-radius: 13.33vw;
    margin: 1vw 2.27vw 1vw 2.3vw;
    background-image: -webkit-linear-gradient(
      left,
      #2948ff,
      #396afc
    ) !important;
    background-image: linear-gradient(90deg, #2948ff, #396afc) !important;
    height: 10.67vw;
    border: none !important;
  }

  :deep(.my-list) {
    margin-top: 10px;
    padding: 5px 0;
    border-radius: 30px;

    .van-cell {
      padding-bottom: 15px;
      width: 90%;
      margin: 0px 16px;
    }

    .van-button__content {
      i.van-icon {
        font-size: 24px;
        color: #fff;
      }
    }
    i.van-icon {
      font-size: 16px;
      color: rgb(153, 153, 153);
    }
    .van-cell__title {
      margin-left: 10px;
    }
  }
}
</style>
