<template>
  <div
    class="login rel container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <van-form @submit="onSubmit">
      <van-cell-group inset class="login-form border-r5 mt-20">
        <div class="logo txt-center">
          <img src="@/assets/photo_2022-11-27_16-44-29.jpg" alt="" />
        </div>

        <van-field
          v-model="form.username"
          center
          :label-width="locale === 'en' ? '70px' : '50px'"
          :placeholder="$t('public.input') + $t('login.username')"
          clearable
          class="mt-10"
        >
          <template #left-icon>
            <van-icon :name="img_name" color="red" size="30" />
          </template>
        </van-field>
        <van-field
          v-model="form.password"
          :type="isShowPwd ? 'text' : 'password'"
          :right-icon="isShowPwd ? 'eye-o' : 'closed-eye'"
          :label-width="locale === 'en' ? '70px' : '50px'"
          :placeholder="$t('public.input') + $t('login.password')"
          class="mt-10"
          @click-right-icon="showPwd"
        >
          <template #left-icon>
            <van-icon :name="img_pass" color="red" size="30" />
          </template>
        </van-field>
        <van-row class="link">
          <van-col span="12">
            <div class="con blue" @click="goKefu">
              {{ $t("login.forgot") }}?
            </div>
          </van-col>
        </van-row>
        <div style="margin: 16px 0">
          <van-button round block type="primary" native-type="submit">
            {{ $t("login.login") }}
          </van-button>
        </div>
        <div style="margin: 16px 0">
          <van-button
            round
            block
            plain
            native-type="submit"
            @click="showNetwork = true"
          >
            线路选择
          </van-button>
        </div>
        <van-row class="link">
          <van-col span="12">
            <div class="con blue" @click="showPicker = true">
              {{ lang }}
            </div>
          </van-col>
          <van-col span="12" class="txt-right">
            <div class="con blue" @click="showRegister = true">
              {{ $t("login.register") }}
            </div>
          </van-col>
        </van-row>
      </van-cell-group>
    </van-form>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onLangConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

    <!-- <van-popup v-model:show="showNetwork" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup> -->
    <!-- 注册 -->

    <van-popup
      v-model:show="showRegister"
      position="right"
      closeable
      close-icon="close"
      close-icon-position="top-left"
      :style="{ height: '100%', width: '100%' }"
    >
      <register
        @success="
          () => {
            showRegister = false;
          }
        "
      />
    </van-popup>
  </div>
</template>

<script setup>
import register from "./register";
import { ref, reactive } from "vue";
import { Notify, Toast } from "vant";
import { useRouter } from "vue-router";
import { login } from "@/api/user";
import { getConfigs } from "@/api/public";
import { useI18n } from "vue-i18n";
import store from "@/store";
const router = useRouter();
const { locale } = useI18n();
const lan = localStorage.getItem("lang") || "zh";
import { browserVersion1 } from "@/utils/indexList";
const lang = ref(lan ? "简体中文" : "English");

const configInfo = ref({});
console.log(lang.value);
getConfigs().then((res) => {
  configInfo.value = res;
});
const goKefu = () => {
  // 这里转换成 字符串
  if (browserVersion1().android) {
    window.android.pay(configInfo.value.kefu_url);
  } else {
    window.location.href = configInfo.value.kefu_url;
  }
};
const showRegister = ref(false);
const showPicker = ref(false);
// const showNetwork = ref(false);
const isShowPwd = ref(false);
const columns = ["简体中文", "English"];
const showPwd = () => {
  isShowPwd.value = !isShowPwd.value;
};
const img_name = require("../../assets/q.png");

const img_pass = require("../../assets/w.png");
const onLangConfirm = (value) => {
  const str = value === "English" ? "en" : "cn";
  localStorage.setItem("lang", str);
  lang.value = value;
  locale.value = str;
  showPicker.value = false;
};
onLangConfirm(lang.value);
const form = reactive({
  username: "",
  password: "",
  // username: "zhengshi4",
  // password: "admin123",
});
const onSubmit = async () => {
  const toastLoading = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  login(form)
    .then((res) => {
      console.log(res.data);
      store.commit("user/SET_USERINFO", res.data);
      Toast.clear();
      Notify({
        type: "success",
        message: `欢迎回来${res.data.info.username}！`,
      });
      router.replace("/home");
    })
    .catch((e) => {
      toastLoading.clear();
      throw e;
    });
};
</script>

<style lang="less" scoped>
.login {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .van-form {
    margin-top: 2%;
  }

  .lang {
    width: 100px;
    margin: 0 auto;
  }
  .blue {
    color: #10b2ff !important;
    font-size: 13px;
  }
  .logo {
    margin: 10px 0 30px 0;

    img {
      height: 96px;
    }
  }

  .van-cell-group {
    background: none;
    .van-cell::after {
      display: none;
    }
  }

  .login-form {
    padding: 15px;
  }
  ::v-deep .van-button--primary {
    background: #10b2ff !important;
    color: #fff;
    border-radius: 30px;
    border: none !important;
  }
  ::v-deep .van-button--plain {
    background: #e9a91b;
    color: #fff;
    margin-top: 10px;
    border-radius: 30px;
    border: none !important;
  }
  ::v-deep .van-cell {
    background: #1b1e2f;
    border-radius: 10px;
    height: 44px;
  }
  ::v-deep
    .van-field:not(.uploader, .van-field--disabled)
    .van-cell__value.van-field__value {
    background: none !important;
  }
  ::v-deep .van-row {
    margin-top: 16px;
  }
}
</style>
