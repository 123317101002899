import {
  Field,
  Cell,
  CellGroup,
  Button,
  Icon,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  NoticeBar,
  Col,
  Row,
  Tab,
  Tabs,
  List,
  Tag,
  Loading,
  NavBar,
  Uploader,
  Form,
  Picker,
  Popup,
  Calendar,
  Area,
  Cascader,
  Badge,
  Popover,
} from "vant";

const componentList = [
  Field,
  Cell,
  CellGroup,
  Button,
  Icon,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  NoticeBar,
  Col,
  Row,
  Tab,
  Tabs,
  List,
  Tag,
  Loading,
  NavBar,
  Uploader,
  Form,
  Picker,
  Popup,
  Calendar,
  Area,
  Cascader,
  Badge,
  Popover,
];

export function registerVantComp(app) {
  componentList.forEach((comp) => {
    app.use(comp);
  });
}
