import { createWebHashHistory, createRouter } from 'vue-router';
import routes from './routes';
import store from '../store';

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // 路由缓存
  store.commit('cachedView/ADD_CACHED_VIEW', to);
  if (to.path === '/login') {
    // 检查登录
    next();
  } else if (store.getters.userInfo) {
      next();
    } else {
      next('/login');
    }
});

export default router;
