const state = {
  userInfo: JSON.parse(localStorage.getItem('userInfo'))
};

const mutations = {
  SET_USERINFO: (state, data) => {
    localStorage.setItem('userInfo', JSON.stringify(data));
    state.userInfo = data;
  },
  SET_USERINFO_PUSH: (state, data) => {
    const newInfo = Object.assign(state.userInfo.info, data);
    state.userInfo.info = newInfo;
    localStorage.setItem('userInfo', JSON.stringify(state.userInfo));

  },
  DEL_USERINFO: state => {
    localStorage.clear('userInfo');
    state.userInfo = null;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};