<template>
  <div class="reg rel container van-tab__panel">
    <van-form @submit="onSubmit">
      <van-cell-group inset class="reg-form">
        <h2 class="con">{{ $t("login.register") }}</h2>
        <van-field
          v-model="form.username"
          clearable
          :label-width="locale === 'en' ? '110px' : '70px'"
          :placeholder="$t('public.input') + $t('login.username')"
          class="mt-10"
        >
          <template #left-icon>
            <van-icon :name="img_name" color="red" size="30" />
          </template>
        </van-field>
        <van-field
          v-model="form.password"
          :type="isShowPwd ? 'text' : 'password'"
          :right-icon="isShowPwd ? 'eye-o' : 'closed-eye'"
          :label-width="locale === 'en' ? '110px' : '70px'"
          :placeholder="$t('public.input') + $t('login.password')"
          class="mt-10"
          @click-right-icon="showPwd"
        >
          <template #left-icon>
            <van-icon :name="img_pass" color="red" size="30" />
          </template>
        </van-field>
        <van-field
          v-model="form.checkPass"
          :type="isShowPwd ? 'text' : 'password'"
          :right-icon="isShowPwd ? 'eye-o' : 'closed-eye'"
          :label-width="locale === 'en' ? '110px' : '70px'"
          :placeholder="$t('public.input') + $t('login.password2')"
          class="mt-10"
          @click-right-icon="showPwd"
        >
          <template #left-icon>
            <van-icon :name="img_pass" color="red" size="30" />
          </template>
        </van-field>
        <van-field
          v-model="form.regCode"
          :label-width="locale === 'en' ? '110px' : '70px'"
          :placeholder="$t('public.input') + $t('login.Invitation')"
          class="mt-10"
          @click-right-icon="showPwd"
        >
          <template #left-icon>
            <van-icon :name="img_code" color="red" size="30" />
          </template>
        </van-field>
        <!-- <van-field
          v-model="form.captcha"
          :label-width="locale === 'en' ? '110px' : '70px'"
          :placeholder="$t('public.input') + $t('login.verification')"
          class="mt-10"
        >
          <template #button>
            <img
              :src="imgCaptcha"
              alt=""
              class="captcha"
              @click="imgCaptcha = getCaptcha()"
            />
          </template>
          <template #left-icon>
            <van-icon :name="img_code" color="red" size="30" />
          </template>
        </van-field> -->
        <div style="margin: 36px 16px">
          <van-button round block type="primary" native-type="submit">
            {{ $t("public.submit") }}
          </van-button>
        </div>
      </van-cell-group>
    </van-form>
  </div>
</template>

<script setup>
import { ref, reactive, defineEmits } from "vue";
import { Notify, Toast } from "vant";
import { useRouter } from "vue-router";
import { register, getCaptcha } from "@/api/user";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
// import store from '@/store'
const emit = defineEmits(["success"]);
const router = useRouter();
const isShowPwd = ref(false);
const imgCaptcha = ref(getCaptcha());
const showPwd = () => {
  isShowPwd.value = !isShowPwd.value;
};

const img_name = require("../../assets/q.png");

const img_pass = require("../../assets/w.png");

const img_code = require("../../assets/e.png");

const form = reactive({
  username: "",
  password: "",
  checkPass: "",
  captcha: "",
  regCode: "",
});
const onSubmit = async () => {
  var re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
  if (!re.test(form.username)) {
    Toast.fail("用户名必须含有数字和字母6-18位");
    return;
  }
  const toastLoading = Toast.loading({
    duration: 0,
    forbidClick: true,
    loadingType: "spinner",
  });
  try {
    const res = await register(form);
    console.log(res.data);
    Toast.clear();
    // store.commit('user/SET_USERINFO', res.data)
    Notify({ type: "success", message: "注册成功" });
    emit("success");
  } catch (e) {
    imgCaptcha.value = getCaptcha();
    toastLoading.clear();
  }
};
</script>

<style lang="less" scoped>
.reg {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .van-form {
    margin-top: 10%;
  }
  .con {
    display: inline-block;
    color: #1092d6;
    font-size: 28px;
    border-bottom: solid 4px #1092d6;
    padding: 0 20px;
    line-height: 20px;
    width: 100%;
  }
}
::v-deep .van-cell {
  background: none !important;
  border-radius: none !important;
  height: 11.73vw;
  border-bottom: 0.5px solid #1a03e6;
  width: 90%;
  margin-left: 15px;
}
</style>

