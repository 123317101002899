<template>
  <div
    class="customer container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <img style="width: 100%" src="@/assets/bg1.08f3c538.png" draggable="true" />
    <div class="kf default shadow border-r5">
      <img src="img/kf2.png" alt="" />
      <van-row>
        <van-col span="4"
          ><img src="@/assets/icon-kf.png" style="width: 60%"
        /></van-col>
        <van-col span="12">
          <h1 class="blue">
            {{ $t("public.text8") }}
            <span class="white">{{ $t("public.text9") }}</span>
          </h1>
        </van-col>
        <van-col span="8">
          <van-button round type="primary" @click="openWindow">{{
            $t("public.text10")
          }}</van-button>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="4"
          ><img src="@/assets/icon-kf.png" style="width: 60%"
        /></van-col>
        <van-col span="12">
          <h1 class="blue">
            {{ $t("public.text8") }}
            <span class="white">{{ $t("public.text9") }}</span>
          </h1>
        </van-col>
        <van-col span="8">
          <van-button round type="primary" @click="openWindow2">{{
            $t("public.text10")
          }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script setup>
import store from "@/store";
import { browserVersion1 } from "@/utils/indexList";
const { kefu_url, kefu_url2, id } = store.getters.userInfo.info;
const openWindow = () => {
  var userInfo = { uid: id };
  // 这里转换成 字符串
  let navData = JSON.stringify(userInfo);
  if (browserVersion1().android) {
    window.android.pay(kefu_url + `&userInfo=${navData}`);
  } else {
    window.location.href = kefu_url + `&userInfo=${navData}`;
  }
};
const openWindow2 = () => {
  var userInfo = { uid: id };
  // 这里转换成 字符串
  let navData = JSON.stringify(userInfo);
  if (browserVersion1().android) {
    window.android.pay(kefu_url2 + `&userInfo=${navData}`);
  } else {
    window.location.href = kefu_url2 + `&userInfo=${navData}`;
  }
};
</script>

<style lang="less" scoped>
.default {
  background: #31375d !important;
}
.container {
  padding: 0px !important;
}
::v-deep .van-button--primary {
  background: #0fa3ff !important;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  height: 30px;
  width: 95px;
  line-height: 30px;
  font-size: 10px;
}
.customer {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 90vh;
  position: relative;

  .kf {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: 15px;
    background-color: #31375d;
    -webkit-border-radius: 10px;
    border-radius: 10px;

    img {
      width: 100%;
      border-radius: 10px;
    }

    h1 {
      text-align: left;
      font-size: 20px;
      margin: 0;
      line-height: 26px;

      span {
        font-size: 12px;
        font-weight: normal;
        display: block;
      }
    }

    .van-row {
      margin: 20px 10px;
      padding: 10px 0;
      border-bottom: solid 2px #000;
    }
  }
  ::v-deep .van-col--4 {
    text-align: left;
  }
  ::v-deep .van-col--14 {
    text-align: left;
  }
}
</style>
